import React, { useState } from 'react';
import { graphql as gatsbyql } from 'gatsby';
import compose from '@shopify/react-compose';
import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import * as Grid from '../ui-components/grid';
import * as Page from '../ui-components/page';
import * as Form from '../ui-components/form';

// const CREATE_MODERATOR_MUTATION = gql`
//   mutation CREATE_MODERATOR(
//     $name: String!
//     $password: String!
//     $email: String!
//     $licenseId: String!
//   ) {
//     CreateModerator(
//       name: $name
//       password: $password
//       email: $email
//       licenseId: $licenseId
//     ) {
//       id
//       noStackId
//       name
//     }
//   }
// `;

// const CREATE_PLATFORM_MUTATION = gql`
//   mutation CREATE_PLATFORM(
//     $owner: ID!
//     $ownerPlatformPassword: String!
//     $name: String!
//     $licenseId: String!
//   ) {
//     CreatePlatform(
//       owner: $owner
//       ownerPlatformPassword: $ownerPlatformPassword
//       name: $name
//       licenseId: $licenseId
//     ) {
//       id
//       name
//       clientId
//     }
//   }
// `;

const NewStackPage = ({ createModerator, createPlatform }) => {
  const [licenseId, setLicenseId] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [platformName, setPlatformName] = useState('');
  const [platformPassword, setPlatformPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errors, setErrors] = useState([]);
  const [platformId, setPlatformId] = useState('');

  const isFormIncomplete =
    !licenseId ||
    !name ||
    !password ||
    !email ||
    !platformName ||
    !platformPassword;

  async function handleSubmit(e) {
    e.preventDefault();

    if (isFormIncomplete) {
      return;
    }

    setErrors([]);
    setSubmitting(true);

    try {
      const { data: createModeratorResponse } = await createModerator({
        variables: {
          name,
          password,
          email,
          licenseId,
        },
      });

      const { data: createPlatformResponse } = await createPlatform({
        variables: {
          owner: createModeratorResponse.CreateModerator.id,
          name: platformName,
          ownerPlatformPassword: platformPassword,
          licenseId,
        },
      });

      setPlatformId(createPlatformResponse.CreatePlatform.id);
      setIsSuccessful(true);
    } catch (error) {
      let errorArray = [];

      if (error.graphQLErrors) {
        errorArray = errorArray.concat(error.graphQLErrors);
      }

      const hasNetworkErrors =
        !!error.networkError &&
        !!error.networkError.result &&
        !!error.networkError.result.errors;
      if (hasNetworkErrors) {
        errorArray = errorArray.concat(error.networkError.result.errors);
      }

      if (!error.graphQLErrors && !hasNetworkErrors && error.message) {
        errorArray.push(error.message);
      }

      setErrors(errorArray);
    }

    setSubmitting(false);
  }

  if (isSuccessful) {
    return (
      <Layout>
        <SEO
          title="Created New Stack"
          keywords={[`no-stack`, `gatsby`, `javascript`, `react`]}
        />

        <Grid.MainSection>
          <Grid.Container>
            <Page.PageHeader>
              <Page.PageTitle>Success!</Page.PageTitle>
            </Page.PageHeader>

            <Page.PageMessage>
              <p>
                Congratulations, <strong>{name}</strong>! Your account has been
                created.
              </p>

              <p>
                Stack &quot;<strong>{platformName}</strong>&quot; with ID &quot;
                <strong>{platformId}</strong>&quot; was also created.
              </p>

              <p>
                <strong>no-stack</strong> is currently on heavy alpha
                development. We will inform you firsthand when your account is
                ready for access.
              </p>

              <p>
                Feel free to{' '}
                <a href="mailto://yyakovson613@gmail.com">
                  email us for any inquiries
                </a>
                .
              </p>
            </Page.PageMessage>
          </Grid.Container>
        </Grid.MainSection>
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO
        title="Create a New Stack"
        keywords={[`no-stack`, `gatsby`, `javascript`, `react`]}
      />

      <Grid.MainSection>
        <Grid.Container>
          <Page.PageHeader>
            <Page.PageTitle>Create a NoStack Account</Page.PageTitle>
          </Page.PageHeader>

          <Grid.Row>
            {errors.map((error, i) => (
              <Form.FormError key={i}>{error}</Form.FormError>
            ))}
          </Grid.Row>

          <Form.FormList>
            <Form.FormListItem>
              <label htmlFor="license-id">
                NoStack License ID{' '}
                <span>
                  (
                  <Form.FormListItemLink to="/new-license">
                    Get a License
                  </Form.FormListItemLink>
                  )
                </span>
                <input
                  id="license-id"
                  type="text"
                  value={licenseId}
                  onChange={e => setLicenseId(e.target.value)}
                  placeholder="Your license id"
                  required
                  disabled={submitting}
                />
              </label>
            </Form.FormListItem>

            <Form.FormListItem>
              <label htmlFor="name">
                Username <span>(one word, lowercase)</span>
                <input
                  id="name"
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="desired username"
                  required
                  disabled={submitting}
                />
              </label>
            </Form.FormListItem>

            <Form.FormListItem>
              <label htmlFor="email">
                Email
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="you@email.com"
                  required
                  disabled={submitting}
                />
              </label>
            </Form.FormListItem>

            <Form.FormListItem>
              <label htmlFor="password">
                Password
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder="*******"
                  required
                  disabled={submitting}
                />
              </label>
            </Form.FormListItem>

            <Form.FormListItem>
              <label htmlFor="platform-name">
                Desired Virtual Stack Name <span>(one word, lowercase)</span>
                <input
                  id="platform-name"
                  type="text"
                  value={platformName}
                  onChange={e => setPlatformName(e.target.value)}
                  placeholder="yourvirtualstack"
                  required
                  disabled={submitting}
                />
              </label>
            </Form.FormListItem>

            <Form.FormListItem>
              <label htmlFor="platform-password">
                Stack Password{' '}
                <span>(advisable to not be same as password above)</span>
                <input
                  id="platform-password"
                  type="password"
                  value={platformPassword}
                  onChange={e => setPlatformPassword(e.target.value)}
                  placeholder="*******"
                  required
                  disabled={submitting}
                />
              </label>
            </Form.FormListItem>

            <Form.FormListItem>
              <Form.FormListItemButton
                type="submit"
                role="button"
                onClick={handleSubmit}
                disabled={isFormIncomplete || submitting}
              >
                {submitting ? 'Please wait...' : 'Create!'}
              </Form.FormListItemButton>
            </Form.FormListItem>
          </Form.FormList>
        </Grid.Container>
      </Grid.MainSection>
    </Layout>
  );
};

export default compose(NewStackPage);

export const pageQuery = gatsbyql`
  query {
    site {
      siteMetadata {
        title
      }
    } 
  }
`;
